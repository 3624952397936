import gql from "graphql-tag";

export const MEDIA_ITEM_DETAILS = gql`
  fragment MediaItemDetails on MediaItem {
    sourceType
    dataType
    url
    base64
    darkModeUrl
    lightModeUrl
  }
`;

export const PARTICIPATION_ITEM_FRAGMENT = gql`
  ${MEDIA_ITEM_DETAILS}
  fragment ParticipationItemFragment on ParticipationItem {
    mediaItems {
      # the stack of pfps
      ...MediaItemDetails
    }
    boundaryItemText # "+54 fans"
    infoText # "chatting now"
  }
`;

export const BUTTON_FRAGMENT = gql`
  fragment ButtonFragment on Button {
    action
    iconUrlString
    source
    style
    targetUrlString
    title
  }
`;

export const PILL_DETAILS = gql`
  ${MEDIA_ITEM_DETAILS}
  fragment PillDetails on Pill {
    text
    textColor
    textColorAlpha
    backgroundColor
    backgroundColorAlpha
    borderColor
    borderColorAlpha
    gutterColor
    gutterColorAlpha
    icon {
      ...MediaItemDetails
    }
  }
`;

export const BADGE_FRAGMENT = gql`
  ${BUTTON_FRAGMENT}
  ${MEDIA_ITEM_DETAILS}
  fragment BadgeFragment on Badge {
    text
    badge {
      darkModeUrl
      lightModeUrl
    }
    infoSheet {
      button {
        ...ButtonFragment
      }
      icon {
        ...MediaItemDetails
      }
      subtitle
      title
    }
    analyticsItem {
      eventName
      eventPropertyKeys
      eventPropertyValues
    }
  }
`;

export const POLL_OPTION_FRAGMENT = gql`
  ${PARTICIPATION_ITEM_FRAGMENT}
  fragment PollOptionFragment on PollOption {
    pollOptionId
    clientPollOptionId
    isSelected
    title
    participationItem {
      ...ParticipationItemFragment
    }
    progress
  }
`;

export const POLL_DETAILS_FRAGMENT = gql`
  ${POLL_OPTION_FRAGMENT}
  ${MEDIA_ITEM_DETAILS}
  fragment PollDetailsFragment on PollDetails {
    pollId
    clientPollId
    bunchId
    status
    mediaItem {
      ...MediaItemDetails
    }
    title
    options {
      ...PollOptionFragment
    }
    canEditPoll
  }
`;

export const NFT_DETAILS = gql`
  fragment NftDetails on Nft {
    contractAddress
    description
    media {
      base64
      dataType
      sourceType
      url
    }
    name
    targetUrl
  }
`;

export const COLLECTION_DETAILS = gql`
  ${NFT_DETAILS}
  fragment CollectionDetails on Collection {
    nfts {
      ...NftDetails
    }
  }
`;

export const ACTION_DETAILS = gql`
  fragment ActionDetails on Action {
    buttonActionUrlString
    buttonText
    imageUrlString
    subtitle
    title
  }
`;

export const CHALLENGE_DETAILS = gql`
  ${ACTION_DETAILS}
  fragment ChallengeDetails on Challenge {
    action {
      ...ActionDetails
    }
    imageUrlString
    state
    subtitle
    title
  }
`;

export const CAMPAIGN_DETAILS = gql`
  ${CHALLENGE_DETAILS}
  fragment CampaignDetails on Campaign {
    title
    imageUrlString
    challenges {
      ...ChallengeDetails
    }
  }
`;

export const TOKEN_DETAILS = gql`
  fragment TokenDetails on Token {
    id
    name
    symbol
    tokenImageUrl
    contractAddress
    units
    type
    isActive
  }
`;

export const LINK_DATA_DETAILS = gql`
  fragment LinkDataDetails on LinkData {
    description
    image
    siteName
    title
    type
    url
  }
`;

export const DISCOVER_DETAILS = gql`
  fragment DiscoverDetails on Bunch {
    discoverBackgroundUrl
    discoverTitle
    discoverDescription
    discoverIconUrl
    discoverCategoryBgColor
  }
`;

export const MESSAGE_COUNTS = gql`
  fragment MessageCounts on MessagesObject {
    countAfter
    countBefore
  }
`;

export const MESSAGE_COMPONENT_DETAILS = gql`
  fragment MessageComponentDetails on MessageComponent {
    type
    content
    startIndex
    endIndex
  }
`;

export const MEDIA_OBJECT_DETAILS = gql`
  fragment MediaObjectDetails on MediaObject {
    url
    type
  }
`;

export const LINK_DETAILS = gql`
  fragment LinkDetails on Link {
    userId
    linkId
    url
    imageUrl
    title
    text
  }
`;

export const MEMBER_DETAILS = gql`
  fragment MemberDetails on User {
    userId
    username
    about
    profilePictureUrl
    location
    url
    shareCode
    isVerified
    verifiedImage
    isCreator
    coverPhotoUrl
    ens
    lens
    farcaster
    unstoppable
    instagram
    soundcloud
    spotify
    youtube
    tiktok
    clubhouse
    twitter
    pinterest
    walletAddress
  }
`;

export const RANKING_ITEM_FRAGMENT = gql`
  fragment RankingItem on RankingItem {
    clientRankingItemId
    order
    rankingItemId
    title
  }
`;

export const RANKING_DETAILS_FRAGMENT = gql`
  ${RANKING_ITEM_FRAGMENT}
  ${MEDIA_ITEM_DETAILS}
  ${PARTICIPATION_ITEM_FRAGMENT}
  fragment RankingDetails on RankingDetails {
    bunchId
    canEditRanking
    clientRankingId
    grades {
      backgroundColor {
        selected {
          dark
          light
        }
        unselected {
          dark
          light
        }
      }
      borderColor {
        selected {
          dark
          light
        }
        unselected {
          dark
          light
        }
      }
      textColor {
        selected {
          dark
          light
        }
        unselected {
          dark
          light
        }
      }
      count
      isSelected
      isWinner
      percentageText
      progress
      title
      participationItem {
        ...ParticipationItemFragment
      }
    }
    items {
      ...RankingItem
    }
    mediaItem {
      ...MediaItemDetails
    }
    rankingId
    status
    title
  }
`;

export const LIGHT_MEMBER_DETAILS = gql`
  ${BADGE_FRAGMENT}
  fragment LightMemberDetails on User {
    username
    userId
    profilePictureUrl
    isVerified
    verifiedImage
    decorationBadges {
      ...BadgeFragment
    }
    badgeImageUrl
    walletAddress
  }
`;

export const SCOUT_DETAILS = gql`
  fragment ScoutDetails on User {
    scout {
      perScoutFee
      perUserFee
      scoutId
      status
      stripeAccountId
      stripeCustomerId
      userId
      canAccessWeb
      paymentInfo
      paymentMethod
      referrer
    }
  }
`;
export const REACTION_DETAILS = gql`
  ${MEMBER_DETAILS}
  fragment ReactionDetails on ReactionDetail {
    content
    count
    users {
      ...MemberDetails
    }
  }
`;

export const USER_DETAILS = gql`
  ${MEMBER_DETAILS}
  ${SCOUT_DETAILS}
  fragment UserDetails on User {
    timezone
    email
    phone
    token
    isAdmin
    ...ScoutDetails
    ...MemberDetails
  }
`;

export const WALLET_DETAILS = gql`
  fragment WalletDetails on Wallet {
    address
    balance
    cardBackgroundImageUrlString
    cardCornerAnimationUrlString
    ownerId
    type
    walletId
    walletType
  }
`;

export const MINIMUM_MESSAGE_CONTENT_DETAILS = gql`
  fragment MinimumMessageContentDetails on Message {
    content {
      text
      previewText
    }
  }
`;

export const MESSAGE_CONTENT_DETAILS = gql`
  ${MEDIA_OBJECT_DETAILS}
  ${MESSAGE_COMPONENT_DETAILS}
  fragment MessageContentDetails on Message {
    content {
      text
      previewText
      media {
        ...MediaObjectDetails
      }
      bunchMentions {
        ...MessageComponentDetails
      }
      hashtags {
        ...MessageComponentDetails
      }
      urls {
        ...MessageComponentDetails
      }
      userMentions {
        ...MessageComponentDetails
      }
    }
  }
`;

export const SIDEBAR_MESSAGE_DETAILS = gql`
  ${MINIMUM_MESSAGE_CONTENT_DETAILS}
  fragment MinimalMessageDetails on Message {
    messageId
    bunchId
    userId
    ...MinimumMessageContentDetails
    isModerated
    insertedAt
    updatedAt
  }
`;

export const MESSAGE_FOOTER_DETAILS = gql`
  ${BUTTON_FRAGMENT}
  ${MEDIA_ITEM_DETAILS}
  fragment MessageFooterDetails on MessageFooter {
    mediaItems {
      ...MediaItemDetails
    }
    messageFooterCaption
    messageFooterButton {
      ...ButtonFragment
    }
  }
`;

export const MESSAGE_DETAILS = gql`
  ${LIGHT_MEMBER_DETAILS}
  ${MESSAGE_CONTENT_DETAILS}
  ${POLL_DETAILS_FRAGMENT}
  ${RANKING_DETAILS_FRAGMENT}
  ${MESSAGE_FOOTER_DETAILS}
  ${PILL_DETAILS}
  fragment MessageDetails on Message {
    type
    shareCode
    messageId
    clientMessageId
    bunchId
    userId
    replyCount
    threadProfilePictureUrlStrings
    parentMessageId
    user {
      ...LightMemberDetails
    }
    ...MessageContentDetails
    isModerated
    insertedAt
    updatedAt
    isBoosted
    isPinned
    pinnedBy {
      ...LightMemberDetails
    }
    boostedUsers {
      ...LightMemberDetails
    }
    footer {
      ...MessageFooterDetails
    }
    reactions {
      content
      count
      users
      reactors {
        userId
        username
        name
        profilePictureUrl
      }
    }
    tagItem {
      ...PillDetails
    }
    poll {
      ...PollDetailsFragment
    }
    ranking {
      ...RankingDetails
    }
  }
`;

export const BUNCH_MEMBER_DETAILS = gql`
  ${MEMBER_DETAILS}
  fragment BunchMemberDetails on Bunch {
    members {
      ...MemberDetails
    }
  }
`;

export const LIMITED_BUNCH_DETAILS = gql`
  ${DISCOVER_DETAILS}
  ${SIDEBAR_MESSAGE_DETAILS}
  ${PILL_DETAILS}
  fragment LimitedBunchDetails on Bunch {
    bunchId
    name
    category
    description
    isMuted
    isPinned
    isPublic
    creatorId
    creator {
      userId
      username
      profilePictureUrl
    }
    tagItem {
      ...PillDetails
    }
    slug
    shareCode
    ...DiscoverDetails
    lastMessageAt
    lastMessageRead {
      ...MinimalMessageDetails
    }
    latestMessage {
      ...MinimalMessageDetails
    }
    memberCount
    composerPlaceholderText
  }
`;

export const BUNCH_DETAILS = gql`
  ${DISCOVER_DETAILS}
  ${BUNCH_MEMBER_DETAILS}
  ${MEMBER_DETAILS}
  ${MESSAGE_DETAILS}
  fragment BunchDetails on Bunch {
    bunchId
    name
    description
    category
    creatorId
    creator {
      ...MemberDetails
    }
    shareCode
    isPublic
    ...DiscoverDetails
    updatedAt
    slug
    insertedAt
    lastMessageAt
    lastMessageRead {
      ...MessageDetails
    }
    latestMessage {
      ...MessageDetails
    }
    memberCount
    memberLimit
    ...BunchMemberDetails
    contractAddress
    isTokenLocked
    tokenThreshold
    isMuted
    isPinned
    composerPlaceholderText
  }
`;

export const BUNCH_DETAILS_LIGHT = gql`
  ${LIGHT_MEMBER_DETAILS}
  fragment BunchDetailsLight on Bunch {
    name
    discoverBackgroundUrl
    members {
      ...LightMemberDetails
    }
  }
`;

export const BUNCH_DETAILS_ULTRA_LIGHT = gql`
  fragment BunchDetailsUltraLight on Bunch {
    bunchId
    name
    description
    discoverBackgroundUrl
    discoverIconUrl
    slug
  }
`;

export const ROLE_DETAILS = gql`
  fragment RoleDetails on Role {
    roleId
    canHighlight
    canModerate
    canSend
    color
    imageUrl
    isAdmin
    name
    type
    insertedAt
    updatedAt
  }
`;

export const CONTACT_DETAILS = gql`
  fragment ContactDetails on Contact {
    contactName: name
    phone
    email
    contactUserId
    shareCode
    friendCount
  }
`;

export const SHAREABLE_DETAILS = gql`
  ${BUNCH_DETAILS}
  ${CONTACT_DETAILS}
  ${MEMBER_DETAILS}
  ${MESSAGE_DETAILS}
  fragment ShareableDetails on Shareable {
    appLink
    readable
    shareCode
    shareableObject {
      ...BunchDetails
      ...ContactDetails
      ...MessageDetails
      ...MemberDetails
    }
    shareableId
    shareableType
    webLink
  }
`;

export const REFERRAL_DETAILS = gql`
  ${MEMBER_DETAILS}
  fragment ReferralDetails on Referral {
    referralId
    sharerCode
    sharedCode
    visits
    signups
    signupUsers {
      ...MemberDetails
    }
  }
`;

export const PARTICIPATION_OBJECT_DETAILS = gql`
  ${BUNCH_DETAILS}
  fragment ParticipationObjectDetails on ParticipationObject {
    isParticipating
    role
    status
    bunch {
      ...BunchDetails
    }
  }
`;

export const USER_PROFILE_DETAILS = gql`
  fragment UserProfileDetails on UserProfile {
    userId
    username
    fullname
    profilePictureUrl
    coverPhotoUrl
    verifiedImageUrl
    bio
    location
    bioLink
    shareCode
    friendsCount
    socials {
      imageUrl
      linkUrl
      platformName
      handle
    }
    collections {
      emptyState {
        iconUrl
        title
        buttonTitle
        targetUrl
      }
      name
      pillIconUrl
      displayType
      items {
        imageUrl
        name
        subtitle
        tertiaryTitle
        targetUrl
      }
    }
    topFriends {
      userId
      username
      profilePictureUrl
    }
    friendStatus
  }
`;

export const SHARED_MESSAGE_FRAGMENT = gql`
  ${MEDIA_ITEM_DETAILS}
  ${MESSAGE_DETAILS}
  fragment SharedMessage on SharedMessage {
    accentColor
    backgrounds {
      backgroundImage {
        ...MediaItemDetails
      }
      isDefault
      isSelected
      sharedMessageBackgroundId
      title
      type
    }
    message {
      ...MessageDetails
    }
    shareButtons {
      action
      buttonBackgroundColor
      buttonTextColor
      iconImage {
        ...MediaItemDetails
      }
      targetUrlString
      title
    }
    shareCode
    sharedImage {
      ...MediaItemDetails
    }
    subtitle
    thread {
      ...MessageDetails
    }
    title
  }
`;

export const MODAL_DETAILS = gql`
  ${MEDIA_ITEM_DETAILS}
  ${BUTTON_FRAGMENT}
  fragment ModalDetails on Modal {
    button {
      ...ButtonFragment
    }
    image {
      ...MediaItemDetails
    }
    caption
    title
  }
`;

export const TRANSACTION_REQUEST_DETAILS = gql`
  fragment TransactionRequestDetails on TransactionRequest {
    domain {
      chainId
      name
      verifyingContract
      version
    }
    types {
      forwardRequest {
        name
        type
      }
    }
    request {
      data
      deadline
      from
      to
      gas
      nonce
      value
    }
  }
`;

export const TRANSACTION_DETAILS = gql`
  ${MODAL_DETAILS}
  ${TRANSACTION_REQUEST_DETAILS}
  fragment TransactionDetails on Transaction {
    transactionId
    modal {
      ...ModalDetails
    }
    transactionRequest {
      ...TransactionRequestDetails
    }
  }
`;

export const UI_NOTIFICATION = gql`
  ${BUNCH_DETAILS_ULTRA_LIGHT}
  fragment UiNotification on UiNotification {
    notificationId
    userId
    type
    title
    body
    relatedBunchId
    relatedMessageId
    relatedUserId
    relatedBunch {
      ...BunchDetailsUltraLight
    }
    relatedMessage {
      content {
        text
        previewText
      }
    }
    relatedUser {
      userId
      username
      profilePictureUrl
      walletAddress
    }
    seen
    updatedAt
    insertedAt
    isMemberToRelatedBunch
  }
`;
